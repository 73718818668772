const currencySymbols = {
    EUR: '€',
    GBP: '£',
    CNY: '¥',
    JPY: '¥',
    USD: '$',
    AUD: '$',
    CAD: '$',
} as const;

const currencySortOrder = {
    EUR: 300,
    GBP: 200,
    CNY: 400,
    JPY: 400,
    USD: 100,
    AUD: 400,
    CAD: 400,
} as const;

const getCurrencySymbol = (currencyName: keyof typeof currencySymbols | '') => currencySymbols[currencyName] || '';

const enrichPrices = prices => {
    return prices.map(price => ({
        ...price,
        name: price.currency || price.name,
        symbol: getCurrencySymbol(price.currency || price.name),
        sortOrder: currencySortOrder[price.currency || price.name],
    }));
};

const sortAsc = (a, b) => {
    return a.sortOrder - b.sortOrder;
};

const toPriceListFormat = (amount: number): string => {
    if (amount === 0) {
        return '0';
    }
    if (!amount) {
        return '';
    }
    const abs = Math.abs(amount);
    const integer = parseInt(String(abs), 10);
    let fractional: string | number = Math.round((abs % 1) * 100);
    if (fractional === 0) {
        return `${(amount < 0 ? '-' : '') + integer}`;
    }
    if (fractional < 10) {
        fractional = `0${fractional}`;
    }
    return `${(amount < 0 ? '-' : '') + integer}.${fractional}`;
};

const amountWithCommas = amount => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const getFormattedAmount = (cur, amount, keepNegative) => {
    const num = keepNegative ? amount ?? 0 : Math.abs(amount ?? 0);
    return `${getCurrencySymbol(cur)}${amountWithCommas(num.toFixed(2))}`;
};

export default getCurrencySymbol;
export { enrichPrices, amountWithCommas, getFormattedAmount, currencySymbols, sortAsc, toPriceListFormat };
