const StepEnum = {
    AFFILIATIONS: 'articleAffiliations',
    CONFIRMATION: 'confirmationPage',
    CONFIRM_AUTHORS: 'confirmAuthors',
    COPYRIGHT: 'copyrightOwnership',
    DISCOUNT_AND_BILLING: 'discountAndBillingDetails',
    FUNDERS: 'researchFunders',
    LICENSE_SUBMISSION: 'licenseSubmission',
    LICENSE_TYPES: 'licenseAgreementTypes',
    OPEN_ACCESS_COVERAGE: 'openAccessCoverage',
    OPEN_ACCESS_OPTIONS: 'openAccessOption',
    PAYMENT: 'onlineOpenPayment',
} as const;

export default StepEnum;
